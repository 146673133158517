import EventManager, {Unsubscriber} from "../../event/EventManager";
import {ModelManager} from "./ModelManager";
import {
    EventType,
    IChartEvent, IModelUpdatedOnNodeLabelUpdateEvent, INodeEvent, INodeLabelUpdateCancelledEvent,
} from "../../event/Event";
import {
    ClipboardEventType, CopyItemsToClipboardEvent,
    CreateItemsFromClipboardEvent
} from "../../../pages/main/content/diagrams/diagrameditor/ClipboardEvents";
import Snackbar from "../../../pages/main/content/snackbar/Snackbar";
import {Point} from "../util/GeometryUtils";
import store from "../../../store/Store";
import SelectionManager from "./SelectionManager";
import {_transl} from "../../../store/localization/TranslMessasge";
import {
    DiagramEditorTranslationKey
} from "../../../pages/main/content/diagrams/diagrameditor/DiagramEditorTranslationKey";
import {ValidationError} from "../../ValidationError";
import RenderMode from "../context/RenderMode";
import {IMode} from "../model/IMode";
import {DiagramEditorEventType, ModalWindowVisibilityChangedEvent} from "../../event/diagrameditor/DiagramEditorEvents";
import {
    SubmodelExchangeDtoBuilder
} from "../../../pages/main/content/diagrams/diagrameditor/submodelexchange/builder/SubmodelExchangeDtoBuilder";
import {
    SubmodelExchangeErrorCodes
} from "../../../pages/main/content/diagrams/diagrameditor/submodelexchange/SubmodelExchangeErrorCodes";
import {
    SubmodelExchangeEncryptor
} from "../../../pages/main/content/diagrams/diagrameditor/submodelexchange/SubmodelExchangeEncryptor";
import {IDiagramNodeDto} from "../../apis/diagram/IDiagramNodeDto";

export default class ItemsClipboardManager {

    private eventManager: EventManager;
    private modelManager: ModelManager;
    private selectionManager: SelectionManager;
    private diagramId: string;
    private mode: IMode;
    private openedModalWindowsCounter: number;
    private submodelExchangeDtoBuilder: SubmodelExchangeDtoBuilder;
    private submodelExchangeEncryptor: SubmodelExchangeEncryptor;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager, modelManager: ModelManager, selectionManager: SelectionManager, diagramId: string, mode: IMode) {
        this.eventManager = eventManager;
        this.modelManager= modelManager;
        this.selectionManager = selectionManager;
        this.diagramId = diagramId;
        this.mode = mode;


        document.addEventListener("paste", this.handlePasteEvent.bind(this));
        this.unsubscribers.push(() => document.removeEventListener("paste", this.handlePasteEvent.bind(this)));

        this.unsubscribers.push(eventManager.subscribeListener<IChartEvent>(EventType.CHART_KEYDOWN, this.handleChartCopyEvent.bind(this)));
        this.unsubscribers.push(eventManager.subscribeListener<IChartEvent>(EventType.CHART_KEYDOWN, this.handleChartPasteEvent.bind(this)));
        this.unsubscribers.push(eventManager.subscribeListener(EventType.NODE_DBLCLICK, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODE_LABEL_UPDATE, this.handleModelUpdatedOnNodeLabelUpdateEvent.bind(this)));
        this.unsubscribers.push(eventManager.subscribeListener(EventType.NODE_LABEL_UPDATE_CANCELLED, this.handleNodeLabelUpdateCancelledEvent.bind(this)));
        this.unsubscribers.push(eventManager.subscribeListener(DiagramEditorEventType.MODAL_WINDOW_VISIBILITY_CHANGED, this.handleModalWindowVisibilityChangedEvent.bind(this)));
        this.unsubscribers.push(eventManager.subscribeListener(ClipboardEventType.COPY_ITEMS_TO_CLIPBOARD, this.handleCopyItemsToClipboardEvent.bind(this)));

        this.openedModalWindowsCounter = 0;

        this.submodelExchangeDtoBuilder = new SubmodelExchangeDtoBuilder();
        this.submodelExchangeEncryptor = new SubmodelExchangeEncryptor();
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(modelManager: ModelManager) {
        this.modelManager = modelManager;
    }

    private handleNodeEvent(event: INodeEvent) {
        if (event.type === EventType.NODE_DBLCLICK) {
            this.openedModalWindowsCounter += 1;
        }
    }

    private handleModelUpdatedOnNodeLabelUpdateEvent(event: IModelUpdatedOnNodeLabelUpdateEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_NODE_LABEL_UPDATE) {
            if (this.openedModalWindowsCounter > 0) {
                this.openedModalWindowsCounter -= 1;
            }
        }
    }

    private handleNodeLabelUpdateCancelledEvent(event: INodeLabelUpdateCancelledEvent) {
        if (event.type === EventType.NODE_LABEL_UPDATE_CANCELLED) {
            if (this.openedModalWindowsCounter > 0) {
                this.openedModalWindowsCounter -= 1;
            }
        }
    }

    private handleModalWindowVisibilityChangedEvent(event: ModalWindowVisibilityChangedEvent) {
        if (event.isVisible) {
            this.openedModalWindowsCounter += 1;
        } else if (this.openedModalWindowsCounter > 0) {
            this.openedModalWindowsCounter -= 1;
        }
    }

    private handleCopyItemsToClipboardEvent(event: CopyItemsToClipboardEvent) {
        this.copyNodesToClipboard(event.nodes);
    }

    private handleChartCopyEvent(event: IChartEvent) {
        if (event.type === EventType.CHART_KEYDOWN && event.event.key === "c" && (event.event.ctrlKey === true || event.event.metaKey === true)) {
            if (!event.event.repeat) {
                let selectedNodes = this.selectionManager.getSelectedNodes();
                if (selectedNodes.length > 0) {
                    this.copyNodesToClipboard(selectedNodes);
                }
            }
        }
    }

    private copyNodesToClipboard(selectedNodes: Array<IDiagramNodeDto>) {
        const submodelExchange = this.submodelExchangeDtoBuilder.buildSubmodelExchangeDto(selectedNodes, this.diagramId,
            window.location.href, this.modelManager.getModelAccessor().getElementsForNodes(selectedNodes));
        navigator.clipboard.writeText(this.submodelExchangeEncryptor.encryptSubmodel(submodelExchange))
            .then(() => Snackbar.info(_transl(DiagramEditorTranslationKey.COPY_TO_CLIPBOARD_SUCCESS_INFO)));
    }

    private handleChartPasteEvent(event: IChartEvent) {
        if (this.mode.mode === RenderMode.EDIT && this.openedModalWindowsCounter === 0 &&
                event.type === EventType.CHART_KEYDOWN && event.event.key === "v" && (event.event.ctrlKey === true || event.event.metaKey === true)) {
            if (!event.event.repeat) {
                if (navigator.clipboard.readText) {
                    navigator.clipboard.readText()
                        .then((clipboardContent) => {
                            this.pasteFromClipboard(clipboardContent);
                        })
                        .catch(err => {
                            console.error(err);
                            if (err instanceof ValidationError && err.error.code === SubmodelExchangeErrorCodes.WRONG_VERSION) {
                                Snackbar.error(_transl(DiagramEditorTranslationKey.FAILED_TO_PARSE_ITEMS_FROM_CLIPBOARD_WRONG_VERSION));
                            } else if (err instanceof ValidationError && err.error.code === SubmodelExchangeErrorCodes.INVALID_INPUT) {
                                Snackbar.error(_transl(DiagramEditorTranslationKey.FAILED_TO_PARSE_ITEMS_FROM_CLIPBOARD_INVALID_INPUT));
                            }
                        });
                } else {
                    console.log("Pasting using execCommand");
                    const status = document.execCommand("paste");
                    console.log(`Pasting status: ${status}`);
                }
            }
        }
    }

    private handlePasteEvent(event: ClipboardEvent) {
        console.log("handlePasteEvent...");
        if (this.openedModalWindowsCounter === 0) {
            const content = event.clipboardData?.getData("text/plain");
            if (content) {
                this.pasteFromClipboard(content);
            }
        }
    }

    private pasteFromClipboard(clipboardContent: string) {
        const submodelExchange = this.submodelExchangeEncryptor.decryptSubmodel(clipboardContent);
        const sameUrl = window.location.href === submodelExchange.url;
        const sameDiagram = this.diagramId === submodelExchange.diagramId;
        const firstNodeStartPoint = this.getFirstNodeStartPoint();

        const event: CreateItemsFromClipboardEvent = {
            type: ClipboardEventType.CREATE_ITEMS_FROM_CLIPBOARD,
            nodes: submodelExchange.nodes,
            sameDiagram: sameDiagram,
            sameUrl: sameUrl,
            startPoint: firstNodeStartPoint,
        };
        this.eventManager.publishEvent(event);
    }

    private getFirstNodeStartPoint(): Point {
        const diagramNodes = this.modelManager.getDiagramNodes();
        if (diagramNodes.length === 0) {
            return new Point(0, 0);
        } else {
            let minX: number | null = null;
            let maxY: number | null = null;
            for (const node of diagramNodes) {
                minX = minX === null ? node.x : Math.min(minX, node.x);
                const nodeMaxY = node.y + node.h;
                maxY = maxY === null ? nodeMaxY : Math.max(maxY, nodeMaxY);
            }
            maxY = (maxY as number) + store.getState().diagramDefaults.spacing;
            return new Point(minX as number, maxY as number);
        }
    }

}

