import {ValidationError} from "../../../../../../common/ValidationError";
import {SubmodelExchangeDto} from "./dto/SubmodelExchangeDto";
import {SubmodelExchangeErrorCodes} from "./SubmodelExchangeErrorCodes";

export const VERSION = "v1";

export class SubmodelExchangeEncryptor {

    encryptSubmodel(submodelExchangeDto: SubmodelExchangeDto): string {
        return JSON.stringify(submodelExchangeDto);
    }

    isValidEncryptedSubmodel(encryptedData: string | undefined | null): boolean {
        if (!encryptedData) {
            return false;
        }
        try {
            const data = JSON.parse(encryptedData);
            if (data.version === VERSION) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    decryptSubmodel(encryptedData: string): SubmodelExchangeDto {
        try {
            const data = JSON.parse(encryptedData);
            if (data.version === VERSION) {
                return data as SubmodelExchangeDto;
            } else {
                throw new ValidationError({code: SubmodelExchangeErrorCodes.WRONG_VERSION});
            }
        } catch (error) {
            if (error instanceof ValidationError && error.error.code === SubmodelExchangeErrorCodes.WRONG_VERSION) {
                throw error;
            } else {
                throw new ValidationError({code: SubmodelExchangeErrorCodes.INVALID_INPUT});
            }
        }
    }

}

