import {Action, applyMiddleware, combineReducers, compose, createStore, DeepPartial} from "redux";
import {combineEpics, createEpicMiddleware, EpicMiddleware} from 'redux-observable'
import {IUserState, userReducer} from "./User";
import {
    filterReducer as elementsPageFilterReducer,
    IFilterState as IElementsPageFilterState,
    refetchFilterEpic as elementsPagePublicRefetchFilterEpic,
} from "./elements/Elements";
import {
    dialogFilterReducer as elementsPageDialogFilterReducer,
    IFilterState as IElementsPageDialogFilterState,
} from "./elements/ElementsDialog";
import {
    dialogFilterReducer as diagramsPageDialogFilterReducer,
    dialogGridReducer as diagramsPageDialogGridReducer,
    dialogGridRefreshEpic as diagramsPageDialogGridRefreshEpic,
    IFilterState as IDiagramsPageDialogFilterState,
} from "./diagrams/DiagramsDialog";
import {
    AttachmentDeleteResourceIdType,
    AttachmentDownloadResourceIdType,
    AttachmentResourceType,
    diagramAttachmentDeleteArrayEpic as diagramDetailPageItemAttachmentDeleteArrayEpic,
    diagramAttachmentDeleteArrayReducer as diagramDetailPageItemAttachmentDeleteArrayReducer,
    diagramAttachmentDownloadArrayEpic as diagramDetailPageItemAttachmentDownloadArrayEpic,
    diagramAttachmentDownloadArrayReducer as diagramDetailPageItemAttachmentDownloadArrayReducer,
    diagramAttachmentEpic as diagramDetailPageItemAttachmentEpic,
    diagramAttachmentReducer as diagramDetailPageItemAttachmentsReducer,
    diagramAttachmentUploadArrayEpic as diagramDetailPageItemAttachmentUploadArrayEpic,
    diagramAttachmentUploadArrayReducer as diagramDetailPageItemAttachmentUploadArrayReducer,
    elementAttachmentDeleteArrayEpic as elementDetailPageItemAttachmentDeleteArrayEpic,
    elementAttachmentDeleteArrayReducer as elementDetailPageItemAttachmentDeleteArrayReducer,
    elementAttachmentDownloadArrayEpic as elementDetailPageItemAttachmentDownloadArrayEpic,
    elementAttachmentDownloadArrayReducer as elementDetailPageItemAttachmentDownloadArrayReducer,
    elementAttachmentEpic as elementDetailPageItemAttachmentEpic,
    elementAttachmentReducer as elementDetailPageItemAttachmentsReducer,
    elementAttachmentUploadArrayEpic as elementDetailPageItemAttachmentUploadArrayEpic,
    elementAttachmentUploadArrayReducer as elementDetailPageItemAttachmentUploadArrayReducer,
} from "./common/Attachments"
import {
    authorOptionsEpic as commonAuthorOptionsEpic,
    authorOptionsReducer as commonAuthorOptionsReducer,
    collectionOptionsEpic as commonCollectionOptionsEpic,
    collectionOptionsReducer as commonCollectionOptionsReducer,
    labelOptionsEpic as commonLabelOptionsEpic,
    labelOptionsReducer as commonLabelOptionsReducer,
    stateOptionsEpic as commonStateOptionsEpic,
    stateOptionsReducer as commonStateOptionsReducer,
    typeOptionsEpic as commonTypeOptionsEpic,
    typeOptionsReducer as commonTypeOptionsReducer,
    viewpointOptionsEpic as commonViewpointOptionsEpic,
    viewpointOptionsReducer as commonViewpointOptionsReducer,
} from "./common/Options";
import {
    filterReducer as diagramsPageFilterReducer,
    gridReducer as diagramsPageGridReducer,
    gridRefreshEpic as diagramsPageGridRefreshEpic,
    IFilterState as IDiagramsPageFilterState,
    refetchFilterEpic as diagramsPagePublicRefetchFilterEpic,
} from "./diagrams/Diagrams";
import {IFetchableResourceState} from "./common/FetchableResource";
import {IElementStateDto, IElementTypeDto} from "../common/apis/Elements";
import {CollectionDto} from "../common/apis/collection/CollectionDto";
import {IDownloadableResourceState} from "./common/DownloadableResource";
import {IDeletableResourceState} from "./common/DeletableResource";
import {IUploadableResourceState} from "./common/UploadableResource";
import {gridPersistentStateReducer, IGridPersistentState} from "./common/Grid";
import {IUiComponentsState, uiComponentsStateReducer} from "./common/UiComponentsState";
import {ITranslation} from "./localization/ITranslation";
import translationReducer from "./localization/TranslationReducer";
import dashboardReducer from "./dashboard/DashboardReducer";
import diagramDefaultsReducer from "../diagram/defaults/store/DiagramDefaultsReducer";
import {Dashboard} from "./dashboard/Dashboard";
import {UserDto} from "../common/apis/user/UserDto";
import {ViewpointDto} from "../common/apis/ViewpointService";
import {ILabelDto} from "../common/apis/label/ILabelDto";
import {DiagramInfoDto} from "../common/apis/diagram/DiagramInfoDto";
import {TrackerConfigDto} from "../configuration/TrackerConfigDto";
import {trackerConfigReducer} from "./config/TrackerConfigReducer";
import {DiagramDefaultsDto} from "../common/apis/diagram/DiagramDefaultsDto";


declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function
    }
}

export interface IApplicationState {
    user: IUserState,
    uiComponentsState: IUiComponentsState,
    pages: {
        common: {
            options: {
                collections: IFetchableResourceState<CollectionDto[]>,
                labels: IFetchableResourceState<ILabelDto[]>,
                viewpoints: IFetchableResourceState<ViewpointDto[]>
                states: IFetchableResourceState<IElementStateDto[]>,
                types: IFetchableResourceState<IElementTypeDto[]>,
                authors: IFetchableResourceState<UserDto[]>,
            },
        },
        gridPersistentState: IGridPersistentState,
        attachments: {
            attachments: {
                diagrams: IFetchableResourceState<AttachmentResourceType>,
                items: IFetchableResourceState<AttachmentResourceType>,
            },
            downloadedAttachments: {
                diagrams: IDownloadableResourceState<AttachmentDownloadResourceIdType>[],
                items: IDownloadableResourceState<AttachmentDownloadResourceIdType>[],
            },
            deletedAttachments: {
                diagrams: IDeletableResourceState<AttachmentDeleteResourceIdType>[],
                items: IDeletableResourceState<AttachmentDeleteResourceIdType>[],
            },
            uploadedAttachments: {
                diagrams: IUploadableResourceState[],
                items: IUploadableResourceState[],
            }
        },
        elements: {
            filter: {
                submittedFilter: IElementsPageFilterState,
            },
            dialogFilter: {
                submittedFilter: IElementsPageDialogFilterState,
            },
        },
        diagrams: {
            grid: IFetchableResourceState<DiagramInfoDto[]>,
            dialogGrid: IFetchableResourceState<DiagramInfoDto[]>,
            filter: {
                submittedFilter: IDiagramsPageFilterState,
            },
            dialogFilter: {
                submittedFilter: IDiagramsPageDialogFilterState,
            },
        },
    },
    translation: ITranslation,
    dashboard: Dashboard,
    diagramDefaults: DiagramDefaultsDto,
    trackerConfig: TrackerConfigDto
}

const rootReducer = combineReducers<IApplicationState>({
    user: userReducer,
    uiComponentsState: uiComponentsStateReducer,
    pages: combineReducers({
        common: combineReducers({
            options: combineReducers({
                collections: commonCollectionOptionsReducer,
                labels: commonLabelOptionsReducer,
                viewpoints: commonViewpointOptionsReducer,
                states: commonStateOptionsReducer,
                types: commonTypeOptionsReducer,
                authors: commonAuthorOptionsReducer,
            }),
        }),
        gridPersistentState: gridPersistentStateReducer,
        attachments: combineReducers({
            attachments: combineReducers({
                diagrams: diagramDetailPageItemAttachmentsReducer,
                items: elementDetailPageItemAttachmentsReducer,
            }),
            downloadedAttachments: combineReducers({
                diagrams: diagramDetailPageItemAttachmentDownloadArrayReducer,
                items: elementDetailPageItemAttachmentDownloadArrayReducer,
            }),
            deletedAttachments: combineReducers({
                diagrams: diagramDetailPageItemAttachmentDeleteArrayReducer,
                items: elementDetailPageItemAttachmentDeleteArrayReducer,
            }),
            uploadedAttachments: combineReducers({
                diagrams: diagramDetailPageItemAttachmentUploadArrayReducer,
                items: elementDetailPageItemAttachmentUploadArrayReducer,
            }),
        }),
        elements: combineReducers({
            filter: combineReducers({
                submittedFilter: elementsPageFilterReducer,
            }),
            dialogFilter: combineReducers({
                submittedFilter: elementsPageDialogFilterReducer,
            })
        }),
        diagrams: combineReducers({
            grid: diagramsPageGridReducer,
            dialogGrid: diagramsPageDialogGridReducer,
            filter: combineReducers({
                submittedFilter: diagramsPageFilterReducer,
            }),
            dialogFilter: combineReducers({
                submittedFilter: diagramsPageDialogFilterReducer,
            })
        }),
    }),
    translation: translationReducer,
    dashboard: dashboardReducer,
    diagramDefaults: diagramDefaultsReducer,
    trackerConfig: trackerConfigReducer,
});

const epics = combineEpics(
    // Common option epics
    commonCollectionOptionsEpic, commonLabelOptionsEpic, commonViewpointOptionsEpic, commonStateOptionsEpic, commonTypeOptionsEpic, commonAuthorOptionsEpic,
    // Items page epics
    elementsPagePublicRefetchFilterEpic,
    // Item detail page epics,
    elementDetailPageItemAttachmentEpic, elementDetailPageItemAttachmentDownloadArrayEpic, elementDetailPageItemAttachmentDeleteArrayEpic,
    elementDetailPageItemAttachmentUploadArrayEpic,
    // Diagram page epics
    diagramsPageGridRefreshEpic, diagramsPagePublicRefetchFilterEpic, diagramsPageDialogGridRefreshEpic,
    //DiagramDetail page epics
    diagramDetailPageItemAttachmentEpic, diagramDetailPageItemAttachmentDownloadArrayEpic, diagramDetailPageItemAttachmentDeleteArrayEpic,
    diagramDetailPageItemAttachmentUploadArrayEpic,
);

const composeEnhancers =
    (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

export function configureStore(epicMiddleware:  EpicMiddleware<Action<any>, Action<any>, IApplicationState, any>, initialState?: DeepPartial<IApplicationState>) {
    const middleware = [epicMiddleware];
    const enhancer = composeEnhancers(applyMiddleware(...middleware))
    const store = createStore(rootReducer, initialState, enhancer);
    epicMiddleware.run(epics as any)
    return store;
}

const store = configureStore(createEpicMiddleware<
    Action<any>,
    Action<any>,
    IApplicationState
    >());

export default store;
