import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {IconButton, Paper} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import ElementsFilter from "./ElementsFilter";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ElementTranslationKey} from "./ElementTranslationKey";
import Constants from "../../../../common/Constants";
import {GridPresets} from "../../../../components/grid/presets/GridPresets";
import {useDispatch, useSelector} from "react-redux";
import {IApplicationState} from "../../../../store/Store";
import ElementsGrid, {ElementsGridType} from "./ElementsGrid";
import {getFilterUpdateAction as getElementsFilterUpdateAction, IFilter} from "../../../../store/elements/Elements";
import pagePresetsClient from "../presets/client/PagePresetsClient";
import {PagePresetsDto, PageSettingsDto} from "../presets/client/PagePresetsDto";
import PagePresetsUpdateDialog from "../presets/PagePresetsUpdateDialog";
import PagePresetsManagementDialog from "../presets/PagePresetsManagementDialog";
import TitleHeader from "../../../../components/TitleHeader";
import {Menu} from "../../../../components/menu/Menu";
import {MoreHoriz, SaveAs, Tune} from "@mui/icons-material";
import {MenuItem} from "../../../../components/menu/MenuItem";
import {PagePresetsTranslationKey} from "../presets/PagePresetsTranslationKey";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import Snackbar from "../snackbar/Snackbar";
import {CommonTranslation} from "../CommonTranslation";
import {useLocation} from "react-router-dom";
import {PersistentStateId} from "../../../../store/common/Grid";
import EventManager from "../../../../common/event/EventManager";
import EventManagerContext from "../../../../common/event/EventManagerContext";
import {GridPresetsEventType} from "../presets/GridPresetsEvents";

const useStyles = makeStyles((theme: Theme) => createStyles({
        page: CommonCssStyles.getRootPageStyles(theme),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme),
}));

export const ELEMENTS_PAGE_ID: string = "ELEMENTS_PAGE_ID";
export const ELEMENTS_PAGE_SHOW_DETAIL_QUERY_PARAM = "showDetail";
export const ELEMENTS_PAGE_PRESETS_ID_QUERY_PARAM = "presets";

export default function ElementsPage() {
    const location = useLocation();
    const classes = useStyles();
    const pageSize = Constants.LARGE_GRID_PAGE_SIZE;

    const dispatch = useDispatch();

    const eventManager = useContext<EventManager>(EventManagerContext);

    const pageMenuIconRef = useRef<HTMLButtonElement | null>(null);
    const [pageMenuOpened, setPageMenuOpened] = useState<boolean>(false);

    const [presetsLoaded, setPresetsLoaded] = useState<boolean>(false);

    const [gridPresets, setGridPresets] = useState<GridPresets>();
    const [defaultPresetsAvailable, setDefaultPresetsAvailable] = useState<boolean>(false);

    const [pagePresetsManagementDialogOpened, setPagePresetsManagementDialogOpened] = useState<boolean>(false);
    const [pagePresetsCreateDialogOpened, setPagePresetsCreateDialogOpened] = useState<boolean>(false);

    const lastFilter = useSelector((state: IApplicationState) => state.pages.elements.filter.submittedFilter.lastFilter);
    const [filter, setFilter] = useState<IFilter>(initFilter(lastFilter));

    function initFilter(savedFilter: IFilter): IFilter {
        return {
            nameLike: savedFilter.nameLike || "",
            identifierLike: savedFilter.identifierLike || "",
            searchText: savedFilter.searchText || "",
            validFrom: savedFilter.validFrom || undefined,
            validThru: savedFilter.validThru || undefined,
            selectedTypes: savedFilter.selectedTypes || [],
            selectedLabels: savedFilter.selectedLabels || [],
            selectedCollections: savedFilter.selectedCollections || [],
            selectedStates: savedFilter.selectedStates || [],
            stereotype: savedFilter.stereotype || "",
            maxPageSize: savedFilter.maxPageSize || pageSize,
            pageToken: savedFilter.pageToken || "0",
        };
    }

    const loadPagePresetsById = useCallback((presetsId: number): void => {
        const promises = [
            pagePresetsClient.find(ELEMENTS_PAGE_ID, presetsId),
            pagePresetsClient.findDefaultPresets(ELEMENTS_PAGE_ID),
        ];

        Promise.allSettled(promises)
            .then((results) => {
                const [customPresetsResult, defaultPresetsResult] = results;

                const defaultSettings = defaultPresetsResult.status === "fulfilled" ? defaultPresetsResult.value as PageSettingsDto : undefined;

                if (customPresetsResult.status === "fulfilled") {
                    applyPresets(customPresetsResult.value as PagePresetsDto);
                } else {
                    if (defaultSettings) {
                        setGridPresets(defaultSettings.gridPresets);
                    }
                }

                setDefaultPresetsAvailable(defaultSettings !== undefined);
                setPresetsLoaded(true);
            });
    }, []);

    const loadDefaultPresets = useCallback((): void => {
        pagePresetsClient.findDefaultPresets(ELEMENTS_PAGE_ID)
            .then(settings => {
                if (settings) {
                    setGridPresets(settings.gridPresets);
                }
                setDefaultPresetsAvailable(settings !== undefined);
                setPresetsLoaded(true);
            });
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const presetsId = params.get(ELEMENTS_PAGE_PRESETS_ID_QUERY_PARAM);
        if (presetsId) {
            loadPagePresetsById(Number(presetsId));
        } else {
            loadDefaultPresets();
        }
    }, [location, loadPagePresetsById, loadDefaultPresets]);

    function saveDefaultPresets(): void {
        if (gridPresets) {
            const settings = {
                gridPresets: gridPresets
            };
            pagePresetsClient.setDefaultPresets(ELEMENTS_PAGE_ID, settings)
                    .then(() => {
                        Snackbar.success(_transl(CommonTranslation.DATA_SAVED_SUCESSFULLY));
                        setDefaultPresetsAvailable(settings !== undefined);
                    })
                    .catch((err) => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA), err));
        }
    }

    function deleteDefaultPresets(): void {
        pagePresetsClient.deleteDefaultPresets(ELEMENTS_PAGE_ID)
                .then(() => {
                    setGridPresets(undefined);
                    setDefaultPresetsAvailable(false);

                    eventManager.publishEvent({type: GridPresetsEventType.PRESETS_RESET});
                })
                .catch((err) => {
                    setGridPresets(undefined);
                });
    }

    function applyPresets(pagePresets: PagePresetsDto): void {
        setGridPresets(pagePresets.settings.gridPresets);
        if (pagePresets.settings.filter) {
            setFilter(pagePresets.settings.filter as IFilter);
        }
    }

    function applyPresetsWithRefetch(pagePresets: PagePresetsDto): void {
        applyPresets(pagePresets);
        refetchData();
    }

    function updateGridPresets(gridPresets: GridPresets): void {
        setGridPresets(gridPresets);
    }

    function showPresetsManagement() {
        setPagePresetsManagementDialogOpened(true);
        setPageMenuOpened(false);
    }

    function showSavePresetsAsDialog() {
        setPagePresetsCreateDialogOpened(true);
        setPageMenuOpened(false);
    }

    function updateFilter(filter: IFilter): void {
        setFilter(filter);
    }

    function refetchData() {
        const newFilter = {...filter};
        dispatch(getElementsFilterUpdateAction(newFilter));
    }

    return (
        <>
            <PagePresetsUpdateDialog open={pagePresetsCreateDialogOpened}
                                     onDialogClosed={() => setPagePresetsCreateDialogOpened(false)}
                                     pageId={ELEMENTS_PAGE_ID}
                                     gridPresets={gridPresets}
                                     filter={filter} />

            <PagePresetsManagementDialog open={pagePresetsManagementDialogOpened}
                                         onApplyPresets={presets => applyPresetsWithRefetch(presets)}
                                         onDialogClosed={() => setPagePresetsManagementDialogOpened(false)}
                                         gridId={ELEMENTS_PAGE_ID} />

            <Paper className={classes.page}>
                <TitleHeader title={_transl(ElementTranslationKey.ELEMENTS_TITLE)}
                             right={
                                <IconButton onClick={() => setPageMenuOpened(true)}
                                            ref={element => pageMenuIconRef.current = element}>
                                    <MoreHoriz />
                                </IconButton>
                             } />

                <Menu open={pageMenuOpened}
                      onClose={() => setPageMenuOpened(false)}
                      anchorEl={pageMenuIconRef.current}>
                    <MenuItem label={_transl(PagePresetsTranslationKey.SAVE_PRESETS_AS)}
                              icon={<SaveAs/>}
                              disabled={gridPresets === undefined}
                              onClick={() => showSavePresetsAsDialog()} />
                    <MenuItem label={_transl(PagePresetsTranslationKey.PRESETS_MANAGEMENT)}
                              icon={<Tune/>}
                              onClick={() => showPresetsManagement()} />
                </Menu>

                <div className={classes.controlPageSegment}>
                    <ElementsFilter filter={filter}
                                    onFilterChanged={updateFilter}
                                    onSearchClicked={() => refetchData()} />
                </div>
                <Divider/>
                <div className={classes.controlPageSegment}>
                    {presetsLoaded &&
                        <ElementsGrid
                            filter={lastFilter}
                            refetchData={refetchData}
                            gridPresets={gridPresets}
                            onGridPresetsChanged={updateGridPresets}
                            persistentStateId={PersistentStateId.ELEMENTS_PAGE_GRID}
                            isDefaultPresetsAvailable={defaultPresetsAvailable}
                            onLoadDefaultPresets={() => loadDefaultPresets()}
                            onSavePresetsAsDefault={() => saveDefaultPresets()}
                            onResetDefaultPresets={() => deleteDefaultPresets()}
                            elementsGridType={ElementsGridType.OVERVIEW}
                        />
                    }
                </div>
            </Paper>
        </>
    );
}
