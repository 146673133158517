import React, {useEffect, useRef, useState} from "react";
import {ContextMenu} from "../../../../../diagram/editor/components/menu/ContextMenu";
import {ChatBubble, ContentPaste, SelectAll} from "@mui/icons-material";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {DiagramEditorTranslationKey} from "./DiagramEditorTranslationKey";
import EventManager from "../../../../../common/event/EventManager";
import {ChatEventType, CreateNewChatRequestEvent} from "../../chat/ChatEvents";
import Divider from "@mui/material/Divider";
import {MenuItem} from "../../../../../components/menu/MenuItem";
import {
    SelectAllEvent,
    SelectionEventType
} from "../../../../../common/diagrameditor/manager/selection/SelectionEvents";
import {CommonTranslation} from "../../CommonTranslation";
import {ClipboardEventType, CreateItemsFromClipboardEvent} from "./ClipboardEvents";
import Snackbar from "../../snackbar/Snackbar";
import RenderMode from "../../../../../common/diagrameditor/context/RenderMode";
import {ValidationError} from "../../../../../common/ValidationError";
import {Point} from "../../../../../common/diagrameditor/util/GeometryUtils";
import {SubmodelExchangeEncryptor} from "./submodelexchange/SubmodelExchangeEncryptor";
import {SubmodelExchangeErrorCodes} from "./submodelexchange/SubmodelExchangeErrorCodes";

interface Props {
    opened: boolean;
    clientCoordinates: [number, number];
    transformedClientCoordinates: [number, number];
    onClose: () => void;
    eventManager?: EventManager;
    mode: RenderMode,
    diagramId: string,
}

export default function PaperContextMenu(props: Props) {

    const {opened, onClose, clientCoordinates, transformedClientCoordinates, eventManager, mode, diagramId} = props;

    const submodelExchangeEncryptor = useRef(new SubmodelExchangeEncryptor());
    const [isPasteAvailable, setPasteAvailable] = useState(false);

    const anchorPosition = {
        top: clientCoordinates[1],
        left: clientCoordinates[0]
    };

    useEffect(() => {
        if (opened) {
            if (navigator.clipboard.readText) {
                navigator.clipboard.readText().then(
                    (clipboardContent) => setPasteAvailable(submodelExchangeEncryptor.current.isValidEncryptedSubmodel(clipboardContent)));
            }
        } else {
            setPasteAvailable(false);
        }
    }, [opened]);

    function showChatDialog() {
        if (eventManager) {
            const event: CreateNewChatRequestEvent = {
                type: ChatEventType.CREATE_NEW_CHAT_REQUEST,
                transformedClientX: transformedClientCoordinates[0],
                transformedClientY: transformedClientCoordinates[1]
            };
            eventManager.publishEvent(event);
            onClose();
        }
    }

    function selectAll() {
        if (eventManager) {
            const event: SelectAllEvent = {
                type: SelectionEventType.SELECT_ALL
            }
            eventManager.publishEvent(event);
            onClose();
        }
    }

    function pasteItemsFromClipboard() {
        if (eventManager) {
            if (navigator.clipboard.readText) {
                navigator.clipboard.readText()
                    .then((clipboardContent) => {
                        const submodelExchange = submodelExchangeEncryptor.current.decryptSubmodel(clipboardContent);
                        const sameUrl = window.location.href === submodelExchange.url;
                        const sameDiagram = diagramId === submodelExchange.diagramId;

                        const event: CreateItemsFromClipboardEvent = {
                            type: ClipboardEventType.CREATE_ITEMS_FROM_CLIPBOARD,
                            nodes: submodelExchange.nodes,
                            sameDiagram: sameDiagram,
                            sameUrl: sameUrl,
                            startPoint: Point.of(transformedClientCoordinates[0], transformedClientCoordinates[1]),
                        };
                        eventManager.publishEvent(event);
                    })
                    .catch(err => {
                        if (err instanceof ValidationError && err.error.code === SubmodelExchangeErrorCodes.WRONG_VERSION) {
                            Snackbar.error(_transl(DiagramEditorTranslationKey.FAILED_TO_PARSE_ITEMS_FROM_CLIPBOARD_WRONG_VERSION));
                        } else if (err instanceof ValidationError && err.error.code === SubmodelExchangeErrorCodes.INVALID_INPUT) {
                            Snackbar.error(_transl(DiagramEditorTranslationKey.FAILED_TO_PARSE_ITEMS_FROM_CLIPBOARD_INVALID_INPUT));
                        }
                    });
            }
        }
        onClose();
    }

    return (
        <ContextMenu open={opened}
                     onClose={onClose}
                     anchorPosition={anchorPosition}>
            <MenuItem label={_transl(DiagramEditorTranslationKey.CONTEXT_MENU_ADD_COMMENT)}
                      icon={<ChatBubble/>}
                      disabled={false}
                      onClick={showChatDialog}/>
            <MenuItem label={_transl(DiagramEditorTranslationKey.PASTE_FROM_CLIPBOARD)}
                      shortcut={"Ctrl+V"}
                      icon={<ContentPaste/>}
                      disabled={mode !== RenderMode.EDIT || !isPasteAvailable}
                      onClick={pasteItemsFromClipboard}/>
            <Divider/>

            <MenuItem label={_transl(CommonTranslation.SELECT_ALL)}
                      icon={<SelectAll/>}
                      disabled={false}
                      shortcut={"Ctrl+A"}
                      onClick={selectAll}/>
        </ContextMenu>
    );
}
