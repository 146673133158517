import React, {useEffect, useRef, useState} from "react";
import {Box, Button, IconButton,} from "@mui/material";
import Divider from "@mui/material/Divider";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import ElementsPickGrid from "./ElementsPickGrid";
import {CancelButton} from "../../../../components/button/CancelButton";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ElementTranslationKey} from "./ElementTranslationKey";
import ElementsFilter from "./ElementsFilter";
import {IFilter} from "../../../../store/elements/Elements";
import {useDispatch, useSelector} from "react-redux";
import {IApplicationState} from "../../../../store/Store";
import Constants from "../../../../common/Constants";
import {getDialogFilterUpdateAction} from "../../../../store/elements/ElementsDialog";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../components/dialogs/DialogActions";
import {MoreHoriz, SaveAs, Tune} from "@mui/icons-material";
import {MenuItem} from "../../../../components/menu/MenuItem";
import {PagePresetsTranslationKey} from "../presets/PagePresetsTranslationKey";
import {Menu} from "../../../../components/menu/Menu";
import PagePresetsUpdateDialog from "../presets/PagePresetsUpdateDialog";
import PagePresetsManagementDialog from "../presets/PagePresetsManagementDialog";
import {ELEMENTS_PAGE_ID} from "./ElementsPage";
import {GridPresets} from "../../../../components/grid/presets/GridPresets";
import {PagePresetsDto} from "../presets/client/PagePresetsDto";

interface ElementsPickDialogProps {
    isOpened: boolean,
    isMultiSelection: boolean,

    // callbacks
    onElementsPicked: (elements: Array<ElementDto>) => void,
    onDialogClosed: () => void,

    // other
    onElementsPickedButtonLabel?: string,
}

export default function ElementsPickDialog(props: ElementsPickDialogProps) {
    const pageSize = Constants.LARGE_GRID_PAGE_SIZE;

    const dispatch = useDispatch();

    const [pickedElements, setPickedElements] = useState<Array<ElementDto>>([]);

    const { isOpened, onElementsPickedButtonLabel, isMultiSelection } = props;
    const { onDialogClosed, onElementsPicked } = props;

    const resolvedDialogTitle = _transl(ElementTranslationKey.ELEMENT_PICK_DIALOG_TITLE);
    const saveButtonLabel = onElementsPickedButtonLabel || _transl(ElementTranslationKey.ELEMENT_PICK_DIALOG_SAVE);

    const [gridPresets, setGridPresets] = useState<GridPresets>();

    const pageMenuIconRef = useRef<HTMLButtonElement | null>(null);
    const [pageMenuOpened, setPageMenuOpened] = useState<boolean>(false);

    const [pagePresetsManagementDialogOpened, setPagePresetsManagementDialogOpened] = useState<boolean>(false);
    const [pagePresetsCreateDialogOpened, setPagePresetsCreateDialogOpened] = useState<boolean>(false);

    const lastFilter = useSelector((state: IApplicationState) => state.pages.elements.dialogFilter.submittedFilter.lastFilter);
    const [filter, setFilter] = useState<IFilter>(initFilter(lastFilter));
    function initFilter(savedFilter: IFilter): IFilter {
        return {
            nameLike: savedFilter.nameLike || "",
            identifierLike: savedFilter.identifierLike || "",
            searchText: savedFilter.searchText || "",
            validFrom: savedFilter.validFrom || undefined,
            validThru: savedFilter.validThru || undefined,
            selectedTypes: savedFilter.selectedTypes || [],
            selectedLabels: savedFilter.selectedLabels || [],
            selectedCollections: savedFilter.selectedCollections || [],
            selectedStates: savedFilter.selectedStates || [],
            stereotype: savedFilter.stereotype || "",
            maxPageSize: savedFilter.maxPageSize || pageSize,
            pageToken: savedFilter.pageToken || "0",
        };
    }

    useEffect(() => {
        if (isOpened) {
            refetchData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpened]);

    function onFilterChanged(filter: IFilter) {
        setFilter(filter);
    }

    function showPresetsManagement() {
        setPagePresetsManagementDialogOpened(true);
        setPageMenuOpened(false);
    }

    function showSavePresetsAsDialog() {
        setPagePresetsCreateDialogOpened(true);
        setPageMenuOpened(false);
    }

    function applyPresets(pagePresets: PagePresetsDto): void {
        setGridPresets(pagePresets.settings.gridPresets);
        if (pagePresets.settings.filter) {
            setFilter(pagePresets.settings.filter as IFilter);
        }
        refetchData();
    }

    function updateGridPresets(gridPresets: GridPresets): void {
        setGridPresets(gridPresets);
    }

    function refetchData() {
        const filterCopy: IFilter = {...filter};
        dispatch(getDialogFilterUpdateAction(filterCopy));
    }

    return (
        <>
            <PagePresetsUpdateDialog open={pagePresetsCreateDialogOpened}
                                     onDialogClosed={() => setPagePresetsCreateDialogOpened(false)}
                                     pageId={ELEMENTS_PAGE_ID}
                                     gridPresets={gridPresets}
                                     filter={filter} />

            <PagePresetsManagementDialog open={pagePresetsManagementDialogOpened}
                                         onApplyPresets={presets => applyPresets(presets)}
                                         onDialogClosed={() => setPagePresetsManagementDialogOpened(false)}
                                         gridId={ELEMENTS_PAGE_ID} />

            <Dialog
                open={isOpened}
                aria-labelledby="elements-pick-dialog-title"
                onClose={onDialogClosed}
                fullWidth={true}
                maxWidth={"xl"}
            >
                <DialogTitle id="elements-pick-dialog-title"
                             title={resolvedDialogTitle}
                             right={
                                 <IconButton onClick={() => setPageMenuOpened(true)}
                                             ref={element => pageMenuIconRef.current = element}
                                 >
                                     <MoreHoriz />
                                 </IconButton>
                             }
                             onDialogClosed={onDialogClosed} />

                <DialogContent>
                    <Box padding={theme => theme.spacing(CommonCssStyles.STANDARD_PADDING, 0)}>
                        <ElementsFilter filter={filter}
                                        onFilterChanged={onFilterChanged}
                                        onSearchClicked={() => refetchData()}/>
                    </Box>
                    <Divider />
                    <Box padding={theme => theme.spacing(CommonCssStyles.STANDARD_PADDING, 0, 0, 0)}>
                        <ElementsPickGrid onSelectionChanged={(rowIds, rows) => setPickedElements(rows)}
                                          isMultiSelection={isMultiSelection}
                                          gridPresets={gridPresets}
                                          onGridPresetsChanged={updateGridPresets}
                                          refetchData={refetchData} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onElementsPicked(pickedElements)}
                            variant={"contained"}
                            color="primary"
                            disabled={pickedElements.length === 0}
                    >
                        {saveButtonLabel}
                    </Button>
                    <CancelButton onClick={onDialogClosed} variant={"outlined"}/>
                </DialogActions>
            </Dialog>

            <Menu id={"page-presets-menu"}
                  open={pageMenuOpened}
                  onClose={() => setPageMenuOpened(false)}
                  anchorEl={pageMenuIconRef.current}>
                <MenuItem label={_transl(PagePresetsTranslationKey.SAVE_PRESETS_AS)}
                          icon={<SaveAs/>}
                          disabled={gridPresets === undefined}
                          onClick={() => showSavePresetsAsDialog()} />
                <MenuItem label={_transl(PagePresetsTranslationKey.PRESETS_MANAGEMENT)}
                          icon={<Tune/>}
                          onClick={() => showPresetsManagement()} />
            </Menu>
        </>
    );

}
